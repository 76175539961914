import { lazy } from "react";
import { Route } from "react-router-dom";
import { ProctedRoute, RestrictedRoute } from "./helpers";
import { RouteStore, TabNames } from "./type";

const HomePage = lazy(() =>
  import("../pages/HomePage").then((module) => ({
    default: module.default,
  }))
);

const TeacherPage = lazy(() =>
  import("../pages/TeacherPage").then((module) => ({
    default: module.default,
  }))
);

const OldProject = lazy(() =>
  import("../old-project/src/old-project").then((module) => ({
    default: module.default,
  }))
);

const SchoolPage = lazy(() =>
  import("../pages/SchoolPage").then((module) => ({
    default: module.default,
  }))
);

const AboutPage = lazy(() =>
  import("../pages/AboutPage").then((module) => ({
    default: module.default,
  }))
);

const LoginPage = lazy(() =>
  import("../pages/LoginPage").then((module) => ({
    default: module.default,
  }))
);

const UserBenefitsPage = lazy(() =>
  import("../pages/UserBenefitsPage").then((module) => ({
    default: module.default,
  }))
);

const TermsOfServicePage = lazy(() =>
  import("../pages/TermsOfServicePage").then((module) => ({
    default: module.default,
  }))
);

const HelpDeskPage = lazy(() =>
  import("../pages/HelpDeskPage").then((module) => ({
    default: module.default,
  }))
);

const SignupPage = lazy(() =>
  import("../pages/SignupPage").then((module) => ({
    default: module.default,
  }))
);

const BlogsPage = lazy(() =>
  import("../pages/BlogsPage").then((module) => ({
    default: module.default,
  }))
);

const BlogPage = lazy(() =>
  import("../pages/BlogPage").then((module) => ({
    default: module.default,
  }))
);

const GuidesPage = lazy(() =>
  import("../pages/GuidesPage").then((module) => ({
    default: module.default,
  }))
);

const ShopPage = lazy(() =>
  import("../pages/ShopPage").then((module) => ({
    default: module.default,
  }))
);

const ProfessionalLRPage = lazy(() =>
  import("../pages/ProfessionalLRPage").then((module) => ({
    default: module.default,
  }))
);

const CheckoutSuccessPage = lazy(() =>
  import("../pages/CheckoutSuccessPage").then((module) => ({
    default: module.default,
  }))
);

const SchoolFaqPage = lazy(() =>
  import("../pages/FaqPage/SchoolFaqPage").then((module) => ({
    default: module.default,
  }))
);

const TeacherFaqPage = lazy(() =>
  import("../pages/FaqPage/TeacherFaqPage").then((module) => ({
    default: module.default,
  }))
);

const TeacherApplicationPage = lazy(() => 
  import("../pages/TeacherApplicationPage").then((module) => ({
    default: module.default,
  }))
)

export const ROOT_ROUTERS: RouteStore = {
  SignUp: <Route key='HomePage' path={`/*`} element={<HomePage />} />,
  TeacherPage: (
    <Route
      key='TeacherPage'
      path={`/${TabNames.TEACHERS}`}
      element={<TeacherPage />}
    />
  ),
  SchoolPage: (
    <Route
      key='TeacherPage'
      path={`/${TabNames.SCHOOLS}`}
      element={<SchoolPage />}
    />
  ),
  AboutPage: (
    <Route
      key='AboutPage'
      path={`/${TabNames.ABOUT}`}
      element={<AboutPage />}
    />
  ),
  HelpDeskPage: (
    <Route
      key='HelpDeskPage'
      path={`/${TabNames.HELP_DESK}`}
      element={<HelpDeskPage />}
    />
  ),
  LoginPage: (
    <Route
      key='LoginPage'
      path={`/${TabNames.OLD_PROJECT_URL}/${TabNames.LOGIN}/*`}
      element={
        <RestrictedRoute>
          <LoginPage />
        </RestrictedRoute>
      }
    />
  ),
  UserBenefitsPage: (
    <Route
      key='UserBenefitsPage'
      path={`/${TabNames.SPARK_USER_BENEFITS}`}
      element={<UserBenefitsPage />}
    />
  ),
  Old_Project: (
    <Route
      key='OldProject'
      path={`/${TabNames.OLD_PROJECT_URL}/*`}
      element={
        <ProctedRoute>
          <OldProject />
        </ProctedRoute>
      }
    />
  ),
  TermsOfServicePage: (
    <Route
      key='TermsOfServicePage'
      path={`/${TabNames.TERMS_OF_SERVICE}`}
      element={<TermsOfServicePage />}
    />
  ),
  SignupPage: (
    <Route
      key='SignupPage'
      path={`/${TabNames.OLD_PROJECT_URL}/${TabNames.REGISTER}/*`}
      element={
        <RestrictedRoute>
          <SignupPage />
        </RestrictedRoute>
      }
    />
  ),
  BlogsPage: (
    <Route
      key='SignupPage'
      path={`/${TabNames.BlogsHomePage}`}
      element={<BlogsPage />}
    />
  ),
  BlogPage: (
    <Route
      key='BlogsPage'
      path={`/${TabNames.BlogsHomePage}/:blogId`}
      element={<BlogPage />}
    />
  ),
  GuidesPage: (
    <Route
      key='GuidesPage'
      path={`/${TabNames.GUIDES}/:guideType`}
      element={<GuidesPage />}
    />
  ),
  ProfessionalLRPage: (
    <Route
      key='ProfessionalLRPage'
      path={`/${TabNames.ProfessionalLearningResourcePage}`}
      element={<ProfessionalLRPage />}
    />
  ),
  ShopPage: (
    <Route key='ShopPage' path={`/${TabNames.SHOP}/*`} element={<ShopPage />} />
  ),
  CheckoutSuccessPage: (
    <Route
      key='CheckoutSuccessPage'
      path={`/${TabNames.CHECKOUT_SUCCESS}`}
      element={<CheckoutSuccessPage />}
    />
  ),
  SchoolFaqPage: (
    <Route
      key='SchoolFaqPage'
      path={`/${TabNames.SCHOOL_FAQ}`}
      element={<SchoolFaqPage />}
    />
  ),
  TeacherFaqPage: (
    <Route
      key='TeacherFaqPage'
      path={`/${TabNames.TEACHER_FAQ}`}
      element={<TeacherFaqPage />}
    />
  ),
  TeacherApplicationPage: (
    <Route
      key='TeacherApplicationPage'
      path={`/${TabNames.TEACHER_APPLICATION}/:teacherId`}
      element={<TeacherApplicationPage/>}
    />
  )
};
