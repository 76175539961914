import { useSelector } from "react-redux";
import { Navigate } from "react-router";
import { RootState } from "../redux/store";

interface IRoute {
  children: JSX.Element;
  customizedFallbackRoute?: string;
}

export function RestrictedRoute({ children, customizedFallbackRoute }: IRoute) {
  const isLogined = useSelector((state: RootState) => state.user.login);

  return isLogined ? (
    <Navigate to={`${customizedFallbackRoute ?? "/"}`} replace />
  ) : (
    children
  );
}

export function ProctedRoute({ children, customizedFallbackRoute }: IRoute) {
  const isLogined = useSelector((state: RootState) => state.user.login);

  return isLogined ? (
    children
  ) : (
    <Navigate to={`${customizedFallbackRoute ?? "/"}`} replace />
  );
}
