import { DataSnapshot,serverTimestamp } from "firebase/database";

export type AsyncStatus = "default" | "loading" | "success" | "failure";

export type Res<T> =
  | {
      code: 200;
      data: T;
    }
  | {
      code: 500;
    };

export type FirebaseBlogData = {
  authorDescription: string;
  authorName: string;
  blogContent: string;
  metaData: string;
  poster: string;
  slug: string;
  storageId: string;
  dateCreated: number;
  title: string;
};

export type FirebaseGuidesData = {
  [index: string]: {
    createdBy: string;
    imageName: string;
    imageUrl: string;
    pdfName: string;
    pdfUrl: string;
    timeStamp: number;
    title: string;
  };
};

export type ProfessionalLearningResource = {
  bgColor: string;
  description: string;
  linkWebsite: string;
  logoUrl: string;
  title: string;
};

export type Stock = {
  [stripeId: string]: number;
};

export type StockRules = "min20" | "min0";

export type Product = {
  id: string;
  name: string;
  description: string; // HTML string generated from admin app
  summary: string;
  sizeChart?: string; // img url
  price: number;
  date: number;
  similarItems: {
    [productId: string]: boolean;
  };
  options: {
    [color: string]: {
      hexCode: string;
      images: {
        [imageAlt: string]: string; //Url
      };
      sizes: {
        [size: string]: {
          stripeId: string;
          stock: number;
        };
      };
      primary?: boolean;
    };
  };
  notPublished?: boolean;
  order: number;
  stockRules?: StockRules;
};

export type Products = {
  [productId: string]: Product;
};

export type Review = {
  id: string;
  title: string;
  des: string;
  rates: number;
  customerName: string;
};

export type Reviews = {
  [id: string]: Review;
};

export type IvalidateAndLockStock = {
  color: string;
  size: string;
  fbId: string;
  orderCount: number;
};

export type Term = {
  title: string;
  url: string;
  rank: number;
  storageFileName: string;
  effectiveDate: string;
  expiryDate: string
};

export type Terms = {
  [uid: string]: Term;
};

export type UpcomingTerm = {
  title: string;
  terms: Terms;
};

export type UpcomingTerms = {
  [uid: string]: UpcomingTerm;
};

export type ShopTerms = {
  privacyPolicy: string;
  returnPolicy: string;
  termsAndConditions: string;
};

export type ResValidateAndLockStock = {
  fbId: string;
  stripeId: string;
  validated: boolean;
  currentStock: number;
  productNotExisted?: boolean;
};

export type EmploymentDeclaration = {
  name: string;
  otherNames: string;
  dob: string;
  vitNo: string;
  dateSubmitted: string;
  consentProvided: boolean;
}

export const getEmploymentDeclaration = (data: DataSnapshot): EmploymentDeclaration => {
  return {
    name: data.val().name,
    otherNames: data.val().otherNames,
    dob: data.val().dob,
    vitNo: data.val().vitNo,
    dateSubmitted: data.val().dateSubmitted,
    consentProvided: data.val().consentProvided,
  }
}

export type EmploymentDeclarationSubmission = {
  suitable: boolean;
  ip: string;
  dateResponded: object;
} 