// Apis not required user login
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { get, getDatabase, ref, set, query, equalTo, orderByChild, update} from "firebase/database";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import {
  FirebaseBlogData,
  FirebaseGuidesData,
  Product,
  Products,
  ProfessionalLearningResource,
  ShopTerms,
  Terms,
  UpcomingTerms,
  Res,
  getEmploymentDeclaration,
  EmploymentDeclarationSubmission,
  EmploymentDeclaration,
} from "./types";
import { app } from "./configs";

interface IStoreHelpDeskEnquiry {
  name: string;
  email: string;
  contactNumber: string;
  subject: string;
  issueDescription: string;
}

export const storeHelpDeskEnquiry = async ({
  name,
  email,
  contactNumber,
  subject,
  issueDescription,
}: IStoreHelpDeskEnquiry) => {
  try {
    const timeStamp = moment().unix();
    const db = getDatabase();
    const id = uuidv4();

    await set(ref(db, `helpDesk/${id}`), {
      contact: contactNumber,
      description: issueDescription,
      email: email,
      name,
      subject,
      timeStamp,
    });

    return true;
  } catch (error) {
    console.log("Errors happen when trying to store help desk enquiry.");
    console.log(error);
    return false;
  }
};

export const signInUserWithEmailAndPassword = async (
  email: string,
  password: string
) => {
  try {
    const auth = getAuth(app);
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;

    return {
      uid: user.uid,
      emailIsVerified: user.emailVerified,
    };
  } catch (error: any) {
    const errorCode = error.code;
    const errorMessage = error.message;

    console.log("Errors happen when tring to signin user.");
    console.log(error);

    return {
      errorCode: errorCode,
    };
  }
};

export const getTermsData = async () => {
  const db = getDatabase();
  const reference = ref(db, `termsV2`);

  return await get(reference).then((snapShot) => {
    console.log(snapShot.toJSON())
    return snapShot.toJSON() as Terms;
  });
};

export const getUpcomingTerms = async () => {
  const db = getDatabase();
  const reference = ref(db, `upcomingTermsV2`);

  return await get(reference).then((snapShot) => {
    return (snapShot.toJSON() ?? {}) as UpcomingTerms;
  });
};

export const getAllBlogs = async () => {
  const db = getDatabase();
  const reference = ref(db, `blog/published/`);
  const myQuery = query(reference, orderByChild('visibility'), equalTo(true));

  return await get(myQuery).then((snapShot) =>{
    return snapShot.toJSON() as { [index: string]: FirebaseBlogData } | null;
  })
};

export const getBlogByBlogId = async (id: string) => {
  const db = getDatabase();
  const reference = ref(db, `blog/published/${id}`);

  return await get(reference).then((snapShot) => {
    return snapShot.toJSON() as FirebaseBlogData | null;
  });
};

export const getGuidesData = async (isTeacher: boolean) => {
  const db = getDatabase();
  const reference = ref(
    db,
    `publicDocuments/${isTeacher ? "teacherGuides" : "schoolGuides"}`
  );
  const myQuery = query(reference, orderByChild('visibility'),equalTo(true))

  return await get(myQuery).then((snapShot) =>{
    console.log(snapShot.toJSON())
    return snapShot.toJSON() as  FirebaseGuidesData  | null;
  })
};

export const getProfessionalLearningResources = async () => {
  const db = getDatabase();
  const reference = ref(db, `resources`);

  try {
    const dataSnapshot = await get(reference);
    const professionalLearningResources = await dataSnapshot.toJSON();

    return (professionalLearningResources ?? {}) as {
      [resource: string]: ProfessionalLearningResource;
    };
  } catch (error) {
    console.log(
      "Error happens when trying to fetch professional learning resources of teachers."
    );
    console.log(error);
  }
};

export const getAllMerches = async () => {
  const db = getDatabase();
  const reference = ref(db, `/shop/products`);

  try {
    const dataSnapshot = await get(reference);
    const merches = await dataSnapshot.toJSON();

    return (merches ?? {}) as Products;
  } catch (error) {
    console.log("Errors happen when trying to get all merches.");
    console.log(error);
  }
};

export const getMerchById = async (id: string) => {
  const db = getDatabase();
  const reference = ref(db, `/shop/products/${id}`);

  try {
    const dataSnapshot = await get(reference);
    const merchandise = await dataSnapshot.toJSON();

    return merchandise as Product;
  } catch (error) {
    console.log(`Errors happen when trying to get merchandise with id ${id}.`);
    console.log(error);
  }
};

export const getStock = async (
  fbId: string,
  color: string,
  size: string
): Promise<
  Res<{
    color: string;
    size: string;
    fbId: string;
    stock: number;
    stripeId: string;
  }>
> => {
  try {
    const db = getDatabase();
    const reference = ref(
      db,
      `/shop/products/${fbId}/options/${color}/sizes/${size}`
    );
    const dataSnapshot = await get(reference);
    const sizeData = (await dataSnapshot.toJSON()) as {
      stripeId: string;
      stock: number;
    };

    return {
      code: 200,
      data: {
        stripeId: sizeData.stripeId,
        color,
        size,
        fbId,
        stock: (sizeData.stock ?? 0) as number,
      },
    };
  } catch (error) {
    console.log(
      `Fail to get the stock of product productId: ${fbId}, color: ${color}, size: ${size}`
    );
    console.log(error);
    return { code: 500 };
  }
};

export const getShopTerms = async () => {
  try {
    const db = getDatabase();
    const reference = ref(db, `/shop/terms`);
    const dataSnapshot = await get(reference);
    const shopTerms = (await dataSnapshot.toJSON()) as ShopTerms | null;
    if (!shopTerms) throw new Error("Shop terms data is empty");

    return { code: 200, value: shopTerms };
  } catch (error) {
    console.log("Errors happen when trying to load shop terms.");
    console.log(error);
    return { code: 500 };
  }
};

/* Teacher Application (Employment Declarations) APIs */

export const retrieveEmployentDeclaration = async (teacherId: string): Promise<Res<EmploymentDeclaration>> => {
  try {
    const dbRef = ref(getDatabase(), `employmentDeclarations/${teacherId}`);
    const snap = await get(dbRef);
    if(snap.exists()) {
      if(snap.val().dateResponded) return {code: 500}
      return {data: getEmploymentDeclaration(snap), code:200};
    } else {
        return {code: 500}
    }
} catch(error) {
  console.log("Error happened whilst trying to retrieve teacher information");
  console.log(error);
  return {code: 500}
}
}

export const submitEmploymentDeclaration = async (teacherId: string, data: EmploymentDeclarationSubmission) => {
  try {
    const dbRef = ref(getDatabase(), `employmentDeclarations/${teacherId}`);
    update(dbRef, data);
    return {code:200};
  } catch(error) {
    console.log("Error happened whilst trying to submit this form.");
    console.log(error);
    return {code:500};
  }
}
