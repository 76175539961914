import { createListenerMiddleware, isAnyOf } from "@reduxjs/toolkit";
import { saveShopcartFromStorage } from "./helpers";
import { RootState } from "./store";
import { addToShopCart, deleteFromShopCart } from "./userSlice";

export const listenerMiddleware = createListenerMiddleware();
listenerMiddleware.startListening({
  matcher: isAnyOf(addToShopCart, deleteFromShopCart),
  effect: (action, listenerApi) => {
    saveShopcartFromStorage(
      (listenerApi.getState() as RootState).user.shopCart
    );
  },
});
