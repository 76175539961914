import { ShopCart } from "./types";

const PERSIST_STORE_NAME = "shop_cart";

export const loadShopcartFromStorage = (): ShopCart[] | undefined => {
  try {
    const serializedState = localStorage.getItem(PERSIST_STORE_NAME);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveShopcartFromStorage = (shopcart: ShopCart[]) => {
  try {
    const serializedState = JSON.stringify(shopcart);
    localStorage.setItem(PERSIST_STORE_NAME, serializedState);
  } catch (error) {
    console.log(
      "Errors happen when trying to store shopping cart to local storage."
    );
    console.log(error);
  }
};

export const clearShopcartFromStorage = () => {
  try {
    localStorage.removeItem(PERSIST_STORE_NAME);
  } catch (error) {
    console.log(
      "Errors happen when trying to clear shope cart's local storage"
    );
    console.log(error);
  }
};

export const getOrderCountOfStripeId = (
  shopCart: ShopCart[],
  stripeId: string
) => {
  const selectedItem = shopCart.filter((item) => item.stripeId === stripeId);
  if (selectedItem.length === 0) return 0;
  return selectedItem[0].orderCount;
};
