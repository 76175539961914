import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TscreenType } from "../hooks/useGetScreenType";

interface IUiSlice {
  screenType: TscreenType;
  incompleteEmailSettingModalVisible: boolean;
}

const initialState: IUiSlice = {
  screenType: "PC",
  incompleteEmailSettingModalVisible: false,
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setScreenType: (state, action: PayloadAction<TscreenType>) => {
      state.screenType = action.payload;
    },
    setIncompleteEmailSettingModalVisible: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.incompleteEmailSettingModalVisible = action.payload;
    },
  },
});

export const { setScreenType, setIncompleteEmailSettingModalVisible } =
  uiSlice.actions;

export default uiSlice.reducer;
