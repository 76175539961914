// Basic size, same as the number in webpack.config
export const baseSize = 16;
// Function to set rem
function setRem() {
  const clientScreenWidth = document.documentElement.clientWidth;
  if (clientScreenWidth >= 1240) {
    const scale = clientScreenWidth / 1440;
    // Set font size of the root element
    document.documentElement.style.fontSize = `${Math.min(
      baseSize * scale,
      18
    )}px`;
  } else if (clientScreenWidth < 1240 && clientScreenWidth >= 600) {
    const scale = clientScreenWidth / 744;
    // Set font size of the root element
    document.documentElement.style.fontSize = `${Math.min(
      baseSize * scale,
      18
    )}px`;
  } else {
    const scale = clientScreenWidth / 390;
    document.documentElement.style.fontSize = `${Math.min(
      baseSize * scale,
      18
    )}px`;
  }
}
// inilization
setRem();
// rem Reset rem when the size of window changes
window.onresize = function () {
  setRem();
};
