export const convert1DArrayTo2DArray = <T>(arr: T[], length: number) => {
  const result: T[][] = [];

  if (length === 1) {
    return arr.map((a) => [a]);
  }

  arr.map((a, index) => {
    if ((index + 1) % length === 1) {
      result.push([a as T]);
    } else {
      if (result.length > 0) {
        result[result.length - 1] = [...result[result.length - 1], a as T];
      }
    }
  });

  return result;
};
