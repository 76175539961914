import Box from "@mui/material/Box";
import { Rings } from "react-loader-spinner";

interface ILoadingSpinner {
  fillViewPortWidth?: boolean;
  fillViewPortHeight?: boolean;
}

const LoadingSpinner = ({ fillViewPortWidth, fillViewPortHeight }: ILoadingSpinner) => (
  <Box
    sx={{
      width: fillViewPortWidth ? "100vw" : "unset",
      height: fillViewPortHeight ? "100vh" : "unset",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Rings color={"rgb(255, 178, 2)"} width={450} height={450} />
  </Box>
);

export default LoadingSpinner;
